.jatek{

  h2{
    margin-top: 50px;
  }


  form{
    margin-top: 20px;
    margin-bottom: 50px;

    input[type=radio]{
      display: none;
    }

    .radio{
      .btn{
        margin-top: 18px;
        padding: 12px 55px;
        min-width: 310px;
      }
    }


    >.btn-primary{
      margin-top: 50px;
    }
  }
}
