.fooldal{

  .lead{
    margin: 20px 5px 50px;
    font-size: 20px;
  }

  .btn{
    margin-bottom: 60px;
    font-size: 25px;
  }
}