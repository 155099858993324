.vege {

  h2 {
    margin-top: 0px;
    font-size: 40px;
    text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.9);
  }

  form {
    margin-top: 20px;
    margin-bottom: 30px;

    input[type=radio] {
      display: none;
    }

    .radio {
      .btn {
        margin-top: 15px;
        padding: 10px 50px;
        width: 300px;
      }
    }

    .form-control {
      max-width: 300px;
      margin: 0 auto;
      border-color: rgba(red($barna), green($barna), blue($barna), 0.6);

      &:focus {
        border-color: $barna;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.4), 0 0 30px rgba(red($sotetzold), green($sotetzold), blue($sotetzold), 0.9);
        background: rgba(red($barna), green($barna), blue($barna), 1);
      }
    }

    input[type=text] {
      color: white;
      background: rgba(red($barna), green($barna), blue($barna), 0.8);
    }

    label {
      font-weight: normal;
      font-size: 18px;
      text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.9);
    }

    .cb-label{
      display: inline;
    }

    > .btn-primary {
      margin-top: 0px;
    }
  }
}