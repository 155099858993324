@import "_bootstrap.scss";
@import "_fooldal.scss";
@import "_jatek.scss";
@import "_vege.scss";

body {
  background: #e9ebee;
}

h1, h2, h3 {
  color: $text-color;
}

h2 {
  font-size: 40px;
}

body > .content {
  padding: 0 !important;
}

.btn {
  text-transform: uppercase;
  padding: 10px 20px;
  min-width: 200px;
}

.btn-zold {
  color: white;
  background: $zold;
  border: $barna;
  opacity: 1;
  font-size: 17px;
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.9);

  &:hover, &:focus {
    color: white;
    background: $sotetzold;
    opacity: 1;
  }
}

.alap-doboz {
  background: $zold url('/images/fooldal_08.jpg') 49% 66% no-repeat;
  background-size: cover;
  padding: 30px 20px 25px;
  min-height: 680px;
  position: relative;
}

.fooldal-bg {
  background: $zold url('/images/fooldal_06.jpg') 49% 12% no-repeat;
  background-size: cover;
}

.vege-bg {
  background: $zold url('/images/fooldal_06.jpg') 49% 10% no-repeat;
  background-size: cover;

  .bg-layer{
    @include gradient-vertical($start-color: rgba(20, 20, 20, 0.5), $end-color: rgba(20, 20, 20, 0.2), $start-percent: 10%, $end-percent: 100%);

  }
}

.tartalom {
  z-index: 100;
  position: relative;
}

.bg-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  @include gradient-vertical($start-color: rgba(40, 40, 40, 0.3), $end-color: rgba(70, 70, 70, 0.1), $start-percent: 8%, $end-percent: 100%);
}

p {
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.9);
}


a {
  color: $zold;
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.9);
  &:hover,&:focus{
    color: $sotetzold;
  }
}
